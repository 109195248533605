<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <!-- sorting  -->
          <b-form-group
            label="Sort"
            label-size="sm"
            label-align-sm="left"
            label-cols-sm="3"
            label-for="sortBySelect"
            class="mr-1 mb-md-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              >
                <template #first>
                  <option value="">
                    none
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
              >
                <option :value="false">
                  ASC
                </option>
                <option :value="true">
                  DESC
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>

          <!-- filter -->
          <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(description)="dataDesc">
          {{ dataDesc.item.description | dotConcat(100) }}
        </template>
        <template #cell(actions)="dataAct">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="SettingsIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="gotoDetail(dataAct.item.id)">
                <feather-icon
                  icon="ListIcon"
                  class="mr-50"
                  style="margin-top: -5px"
                />
                <span>Detail</span>
              </b-dropdown-item>
              <b-dropdown-item @click="gotoDelete(dataAct.item.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                  style="margin-top: -5px"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardBody,
  BButton, BFormGroup,
  BTable, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend,
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BButton,
    BFormGroup,
    BTable,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'question', label: 'Question', sortable: true },
        { key: 'start', label: 'Start' },
        { key: 'end', label: 'End' },
        { key: 'actions', label: 'Actions' },
      ],
      active_role: null,
      data: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    items() {
      const polling = this.data
      // this.totalRows = polling.length

      return polling
    },
  },
  mounted() {
    this.getDataPolling()
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getDataPolling() {
      const cid = localStorage.getItem('client_id')
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      // this.$store.dispatch('banner/loadBanner')
      this.$axios.get(`${cid}/member/corporate/polling`, config).then(response => {
        this.data = response.data.data
      })
    },
    gotoDetail(id) {
      this.$router.push({ path: `/polling/detail/${id}` })
    },
    gotoDelete(id) {
      const cid = localStorage.getItem('client_id')

      this.$bvModal
        .msgBoxConfirm('Are you sure to delete this data?', {
          title: 'Delete confirmation',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            const config = {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }

            this.$axios.delete(`${cid}/polling/${id}`, config)
              .then(() => {
                // Success code
                this.$bvToast.toast('Success', {
                  title: 'Delete Polling',
                  variant: 'success',
                  solid: true,
                  toaster: 'b-toaster-bottom-right',
                })

                this.items = []
                this.getDataPolling()
              })
          }
        })
    },
  },
}
</script>
